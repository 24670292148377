import React, { useState } from "react";
import { FieldArray } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import useAuth from "../../../hooks/useAuth";
import StorageLocationAutocomplete from "../../../views/capture/RackLocations/StorageLocationAutocomplete/StorageLocationAutocomplete";
import Input from "../../form/Input";
import { getInitialLocation } from "./utils";
import RestockSubQuantityField from "./RestockSubQuantityField";
import CurrentQuantityView from "./CurrentQuantityView";
import useTempLocation from "./hooks/useTempLocation";
import useTempLocationEnabled from "./hooks/useTempLocationEnabled";
import CurrentQuantityField from "./CurrentQuantityField";
import DeleteLocation from "./DeleteLocation";

export default function RackLocations({
  locations,
  warehouseLocations,
  loadingWarehouseLocations,
}) {
  const { user } = useAuth();
  const { settings } = user.company;
  const isTempLocationEnabled = useTempLocationEnabled();
  const tempLocation = useTempLocation(warehouseLocations);
  const [openRemoveLocation, setOpenRemoveLocation] = useState(null);

  return (
    <Grid container spacing={1}>
      <FieldArray
        name="locations"
        render={arrayHelpers => {
          const handleAddLocation = () => {
            arrayHelpers.push(getInitialLocation(isTempLocationEnabled, tempLocation));
          };

          return (
            <>
              {locations.length > 0 &&
                locations.map((location, index) => {
                  const number = index + 1;

                  return (
                    <React.Fragment key={`location-${location.rack_location}-${index}`}>
                      <Grid item xs={6}>
                        {settings.warehouse_restock_modal ? (
                          <StorageLocationAutocomplete
                            index={index}
                            name="locations"
                            value={arrayHelpers.form.values.locations[index]}
                            loading={loadingWarehouseLocations}
                            warehouseLocations={warehouseLocations}
                            setFieldValue={arrayHelpers.form.setFieldValue}
                          />
                        ) : (
                          <Input
                            name={`locations.${index}.rack_location[0]`}
                            label={`Storage Location ${number}`}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      </Grid>
                      {settings.simple_inventory_modal ? (
                        <Grid item xs={4} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                          <CurrentQuantityField
                            name={`locations.${index}.restock_sub_qty`}
                            location={location}
                          />
                          <DeleteLocation
                            index={index}
                            arrayHelpers={arrayHelpers}
                            openRemoveLocation={openRemoveLocation}
                            setOpenRemoveLocation={setOpenRemoveLocation}
                          />
                        </Grid>
                      ) : (
                        <>
                          {location.sub_qty !== 0 && (
                            <Grid item xs={2} sx={{ display: "flex", alignItems: "center" }}>
                              <CurrentQuantityView name={`locations.${index}.sub_qty`} />
                            </Grid>
                          )}
                          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
                            <RestockSubQuantityField
                              name={`locations.${index}.restock_sub_qty`}
                              label={`Restock Sub QTY ${number}`}
                            />
                          </Grid>
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={handleAddLocation}
                >
                  Add Another Location
                </Button>
              </Grid>
            </>
          );
        }}
      />
    </Grid>
  );
}

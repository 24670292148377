import React, { useState } from "react";
import { useFormikContext } from "formik";
import BuildCircleOutlinedIcon from "@mui/icons-material/BuildCircleOutlined";
import IconButton from "@mui/material/IconButton";
import PalletCountAssistant from "./PalletCountAssistant";
import { getInitialLocation } from "../utils";
import useAuth from "src/hooks/useAuth";
import useQueryParams from "../../../../hooks/useQueryParams";

export default function PalletButton({ warehouseLocations }) {
  const { user } = useAuth();
  const { settings } = user.company;
  const params = useQueryParams();
  const shipmentItemId = params.get("shipmentItemId");
  const initialStep = settings.pallet_breakdown_wizard && shipmentItemId ? 0 : -1;
  const [state, setState] = useState({});
  const [step, setStep] = useState(initialStep);
  const { values, setFieldValue } = useFormikContext();

  const isTempLocationEnabled = settings.temp_warehouse_location;
  const tempLocation = warehouseLocations.find(
    location => location.locationName === "Temp Location"
  );

  const handleNext = ({ done, ...data } = {}) => {
    const newState = { ...state, ...data };
    setState(newState);

    if (done) {
      const { palletCount, boxesCount, piecesPerBox } = newState;
      const quantity = boxesCount * piecesPerBox;

      const newLocations = Array.from({ length: palletCount }, () =>
        getInitialLocation(isTempLocationEnabled, tempLocation, quantity)
      );

      if (
        values.locations.length === 1 &&
        values.locations[0].new === true &&
        values.locations[0].sub_qty === 0
      ) {
        setFieldValue("locations", newLocations);
      } else {
        setFieldValue("locations", [...values.locations, ...newLocations]);
      }

      handleClose();
    } else {
      setStep(prev => prev + 1);
    }
  };

  const handleChange = values => {
    setState(prev => ({ ...prev, ...values }));
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  const handleClose = () => {
    setState({});
    setStep(-1);
  };

  const handleOpenAssistant = () => setStep(0);

  return (
    <>
      <IconButton
        sx={{ ml: 1 }}
        onClick={handleOpenAssistant}
        aria-label="Open pallet count assistant"
      >
        <BuildCircleOutlinedIcon sx={{ fontSize: 36 }} />
      </IconButton>
      {step >= 0 && (
        <PalletCountAssistant
          state={state}
          step={step}
          onClose={handleClose}
          onNext={handleNext}
          onBack={handleBack}
          onChange={handleChange}
        />
      )}
    </>
  );
}

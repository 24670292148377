import React from "react";
import { useFormikContext } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import isEqual from "lodash/isEqual";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import useAuth from "../../../hooks/useAuth";

const styles = {
  submitButton: {
    mr: "1px",
    height: 36,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  dropdownButton: {
    px: 0,
    height: 36,
    minWidth: 40,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
};

export default function InventoryTransactionActions({ loading, initialValues, restockAddToCart }) {
  const { values, submitForm } = useFormikContext();
  const { user } = useAuth();
  const { settings } = user.company;
  const stateUnchanged = isEqual(
    initialValues.locations.map(v => [v.rack_location, v.restock_sub_qty || 0]),
    values.locations.map(v => [v.rack_location, v.restock_sub_qty || 0])
  );
  const disabled = loading || stateUnchanged;

  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <Button
        disabled={disabled}
        variant="contained"
        color="primary"
        onClick={submitForm}
        sx={styles.submitButton}
      >
        Submit
      </Button>
      {!settings.simple_inventory_modal && (
        <Box variant="contained" aria-label="split button">
          <PopupState variant="popover" popupId="demo-popup-menu">
            {popupState => (
              <React.Fragment>
                <Button
                  disabled={disabled}
                  variant="contained"
                  color="primary"
                  {...bindTrigger(popupState)}
                  sx={styles.dropdownButton}
                >
                  <KeyboardArrowDownIcon
                    sx={{ transform: popupState.open ? undefined : "rotate(180deg)" }}
                  />
                </Button>
                <Menu {...bindMenu(popupState)}>
                  <MenuItem
                    onClick={() => {
                      restockAddToCart(values);
                      popupState.close();
                    }}
                  >
                    Submit + Add to Cart
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </Box>
      )}
    </Box>
  );
}

import React from "react";
import { useFormikContext } from "formik";
import sumBy from "lodash/sumBy";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import useAuth from "../../../hooks/useAuth";
import Input from "../../form/Input";
import RackLocations from "./RackLocations";

export function getRestockTotal(locations) {
  return sumBy(locations, "restock_sub_qty") || 0;
}

export default function Fields({ asset, warehouseLocations, loadingWarehouseLocations }) {
  const { user } = useAuth();
  const { values } = useFormikContext();
  const { settings } = user.company;
  const restockTotal = getRestockTotal(values.locations) - values.deletedQuantity;
  const newOpenStockQty = asset.availableQuantity + (restockTotal || 0);
  const hasSubQty = values.locations.some(location => location.sub_qty !== 0);

  return (
    <>
      {settings.restock_additional_fields && (
        <>
          <Grid item xs={6}>
            <Input name="trackingNumber" label="Tracking Number" />
          </Grid>
          <Grid item xs={6}>
            <Input name="carrierName" label="Carrier Name" />
          </Grid>
          <Grid item xs={6}>
            <Input name="senderName" label="Sender Name" />
          </Grid>
          <Grid item xs={6}>
            <Input name="orderNumber" label="Order Number" />
          </Grid>
          <Grid item xs={6}>
            <Input name="poNumber" label="PO#" />
          </Grid>
        </>
      )}
      {!settings.receiving_wizard && ( // If receiving wizard is enabled, don't show notes field
        <>
          <Grid item xs={settings.restock_additional_fields ? 6 : 12}>
            <Input name="notes" label="Receiving Notes" />
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <RackLocations
          locations={values.locations}
          warehouseLocations={warehouseLocations}
          loadingWarehouseLocations={loadingWarehouseLocations}
        />
      </Grid>

      {values.locations.length > 1 && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <TextField
              disabled
              size="small"
              label={
                settings.simple_inventory_modal ? "Total Quantity Change" : "QTY Received Total"
              }
              value={restockTotal || ""}
            />
          </Box>
        </Grid>
      )}

      {hasSubQty && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <TextField
              disabled
              size="small"
              label={`New ${user.company.settings.quantity_label}`}
              value={newOpenStockQty}
            />
          </Box>
        </Grid>
      )}
    </>
  );
}

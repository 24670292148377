import React from "react";
import { Field } from "formik";
import AssetQuantityField from "../../../views/inventory/requests/AssetQuantityField";

export default function CurrentQuantityField({ name, location }) {
  const value = location.sub_qty + (location.restock_sub_qty || 0);

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <AssetQuantityField
            size="small"
            quantity={value}
            onChange={quantity =>
              form.setFieldValue(field.name, (quantity || 0) - location.sub_qty)
            }
            quantityProps={{
              label: "Current QTY",
              style: { minWidth: 110 },
              InputLabelProps: {
                shrink: true,
              },
            }}
          />
        );
      }}
    </Field>
  );
}

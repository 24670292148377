import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteModal from "src/components/images/ConfirmDeleteModal";

export default function DeleteLocation({
  index,
  arrayHelpers,
  openRemoveLocation,
  setOpenRemoveLocation,
}) {
  return (
    <>
      <Tooltip title="Delete Location">
        <IconButton size="small" onClick={() => setOpenRemoveLocation(index)}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      {typeof openRemoveLocation === "number" && (
        <ConfirmDeleteModal
          title="Delete Location"
          descr="Are you sure want to delete the selected location?"
          onClose={() => setOpenRemoveLocation(null)}
          onDelete={() => {
            const subQty = arrayHelpers.form.values.locations[openRemoveLocation].sub_qty || 0;
            arrayHelpers.form.setFieldValue(
              "deletedQuantity",
              arrayHelpers.form.values.deletedQuantity + subQty
            );
            arrayHelpers.remove(openRemoveLocation);
          }}
        />
      )}
    </>
  );
}
